let TechnologiesData = [{
    "type": "Front End Technology",
    "tag":"clientside",
    "status":"Active",
    "title": "PROSOPON",
    "description": "Our innovative front-end client-side technology ensures high-speed, user-friendly access to our services in client-side browsers. Designed for optimal performance with good internet connections, it provides seamless and intuitive service access, enhancing user experience and revolutionizing how users interact with our platform."
},{
    "type": "Back End Technology",
    "tag":"serverside",
    "status":"Active",
    "title": "THEOS",
    "description": "Our cutting-edge back-end server technology revolutionizes server-side operations with unparalleled innovation. Designed for high-performance and secure handling of confidential tasks, it ensures efficient, reliable, and function-based operations, empowering businesses to achieve robust and scalable server management solutions."
},{
    "type": "Cross Platform Technology",
    "tag":"crossplatform",
    "status":"Under Development",
    "title": "NUMEN",
    "description": "Our cutting-edge cross-platform technology enables seamless and innovative services across multiple platforms. Designed for complex implementations, it ensures compatibility and efficiency, providing robust solutions that adapt to various environments. Experience the future of technology with our versatile and powerful cross-platform services."
},{
    "type": "Gaming Technology",
    "tag":"gamingtech",
    "status":"Upcoming",
    "title": "YAHWEH",
    "description": "Our innovative cross-platform gaming technology revolutionizes game development with seamless compatibility across Android, PlayStation, and Xbox. It ensures consistent functionality and delivers an exceptional user experience, enabling developers to create immersive games effortlessly for multiple platforms. Elevate your gaming projects with our cutting-edge technology."
}]

export default TechnologiesData