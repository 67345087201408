import React, { Component } from 'react';
import SocialMediaLinksData from '../../Model/Links/SocialMediaLinks';

class Footer extends Component {
  render() {
    return (
      <footer className="fixed-bottom bg-body-tertiary text-center xc-nav-footer">
        <div className="container pt-4">
          <section className="mb-4">
            {
              SocialMediaLinksData.map((item, index) => {
                return <a data-mdb-ripple-init className="btn btn-link btn-floating btn-lg text-body m-1" role="button" data-mdb-ripple-color="dark" key={index} href={item.url} target="_blank" rel="noopener noreferrer"><i className={item.classname}></i></a>
              })
            }
          </section>
        </div>
      </footer>
    );
  }
}

export default Footer;