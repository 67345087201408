import React, { Component } from 'react';
import ConnectPageComponent from '../Pages/Auth/Connect'
import DisconnectPageComponent from '../Pages/Auth/Disconnect'
import RegistrationPageComponent from '../Pages/Auth/Registration'
import HomePageComponent from '../Pages/Home'
import AccountPageComponent from '../Pages/Auth/Account'
import TechnologiesPageComponent from '../Pages/App/Technologies'
import UpdatesPageComponent from '../Pages/App/Updates'
import ContactPageComponent from '../Pages/Support/Contact'
import TermsPageComponent from '../Pages/Legal/Terms'
import DisclaimerPageComponent from '../Pages/Legal/Disclaimer'
import PageNotFoundComponent from '../../../Shared/Error/PageNotFound'

class RoutesComponent extends Component {
    render() {
        const { page } = this.props
        return (
            <div className="xc-route-component-content">
                { page === "connect" ? <ConnectPageComponent /> : <></> }
                { page === "disconnect" ? <DisconnectPageComponent /> : <></> }
                { page === "registration" ? <RegistrationPageComponent /> : <></> }
                { page === "" ? <HomePageComponent /> : <></> }
                { page === "account" ? <AccountPageComponent /> : <></> }
                { page === "technology" ? <TechnologiesPageComponent /> : <></> }
                { page === "updates" ? <UpdatesPageComponent /> : <></> }
                { page === "contact" ? <ContactPageComponent /> : <></> }
                { page === "terms" ? <TermsPageComponent /> : <></> }
                { page === "disclaimer" ? <DisclaimerPageComponent /> : <></> }
                { page === "*" ? <PageNotFoundComponent /> : <></> }
            </div>
        )
    }
}
export default RoutesComponent