let NewsData = [{
    "id": "1001",
    "tag": "news1001",
    "title": "Theos Published",
    "date": "01 July 2024",
    "content": "Introduced Theos: a groundbreaking back-end technology revolutionizing server-side operations. Theos ensures secure, efficient, and high-performance handling of confidential tasks, empowering businesses with robust and scalable server management solutions. Experience the future of server technology with Theos, setting new standards in innovation and reliability."
},{
    "id": "1002",
    "tag": "news1002",
    "title": "Prosopon Published",
    "date": "02 July 2024",
    "content": "Announced Prosopon: a cutting-edge front-end technology that transforms user interaction. Prosopon offers lightning-fast, user-friendly access to services in client-side browsers, optimized for superior performance with good internet connections. Elevate your digital experience with Prosopon, redefining simplicity and efficiency in web browsing."
},{
    "id": "1003",
    "tag": "news1003",
    "title": "Company Website Published",
    "date": "03 July 2024",
    "content": "XCOLON (OPC) PRIVATE LIMITED is thrilled to announce the launch of our company website: www.xcolon.in. Discover our cutting-edge technologies and innovative solutions designed to empower businesses. Explore our services and stay updated with the latest advancements in server and client-side technologies."
},{
    "id": "1004",
    "tag": "news1004",
    "title": "Our Website Published",
    "date": "04 July 2024",
    "content": "XCOLON (OPC) PRIVATE LIMITED proudly announced the launch of our website: www.xcology.net. Explore our state-of-the-art technologies and innovative solutions, crafted to empower businesses with cutting-edge server and client-side services. Stay connected with the latest advancements and elevate your business with XCOLON."    
}]

export default NewsData