let RoutesData = [{
    "title": "Home",
    "route": ""
// }, {
//     "title": "Connect",
//     "route": "connect"
// }, {
//     "title": "Disconnect",
//     "route": "disconnect"
// }, {
//     "title": "Registration",
//     "route": "registration"
}, {
    "title": "Technologies",
    "route": "technology"
}, {
    "title": "News",
    "route": "updates"
// }, {
//     "title": "Account",
//     "route": "account"
}, {
    "title": "Contact",
    "route": "contact"
}, {
    "title": "PageNotFound",
    "route": "*"
}]

export default RoutesData