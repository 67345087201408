import React, { Component } from 'react';
import NewsData from '../../../../Model/Updates/News';
import UpdatesData from '../../../../Model/Updates/Updates';

class UpdatesPageComponent extends Component {
    render() {
        return (
            <div className="accordion accordion-flush" id="xcaccordion">
                <div className="accordion-item xc-accordion-item text-center mx-auto p-2 w-75">
                    <br/>
                    <h3>Latest News</h3>
                    <br/>
                    <h2 className="accordion-header">
                        <button className="accordion-button xc-accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#xcnews" aria-expanded="true" aria-controls="xcnews">
                            <strong>News</strong>
                        </button>
                    </h2>
                    <div id="xcnews" className="accordion-collapse collapse" data-bs-parent="#xcaccordion">
                        {
                            NewsData.map((item, index) => {
                                return (
                                    <div className="accordion-body" key={index}>
                                        <div className="list-group">
                                            <a href="/#" className="list-group-item list-group-item-action" aria-current="true">
                                                <div className="w-100">
                                                    <h5 className="mb-1 text-center mx-auto p-2 w-100">{item.title}</h5>
                                                    <small>{item.date}</small>
                                                </div>
                                                <br/>
                                                <p className="mb-1">{item.content}</p>
                                            </a>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <br/>
                    <h3>Latest Updates</h3>
                    <br/>
                    <h2 className="accordion-header">
                        <button className="accordion-button xc-accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#xcupdates" aria-expanded="true" aria-controls="xcupdates">
                            <strong>Updates</strong>
                        </button>
                    </h2>
                    <div id="xcupdates" className="accordion-collapse collapse" data-bs-parent="#xcaccordion">
                        {
                            UpdatesData.map((item, index) => {
                                return (
                                    <div className="accordion-body" key={index}>
                                        <div className="list-group">
                                            <a href="/#" className="list-group-item list-group-item-action" aria-current="true">
                                                <div className="w-100">
                                                    <h5 className="mb-1 text-center mx-auto p-2 w-100">{item.title}</h5>
                                                </div>
                                                <p className="mb-1">{item.content}</p>
                                                <p><small>{item.date}</small></p>
                                            </a>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <br/><br/>
                </div>
            </div>
        )
    }
}
export default UpdatesPageComponent