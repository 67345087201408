let HeaderLinksData = [{
    "title": "Technologies",
    "route": "technology"
}, {
    "title": "News & Updates",
    "route": "updates"
}, {
    "title": "Contact",
    "route": "contact"
}]

export default HeaderLinksData