import React, { Component } from 'react';
import TechnologiesData from '../../../Model/Technology/Technologies';

class HomePageComponent extends Component {
    render() {
        let technologiesdata = TechnologiesData;
        return (
            <div>
                <div className="card text-center">
                    <div className="card-body xc-card-body">
                        <br />
                        <h2 className="card-text">𝗧𝗿𝗮𝗻𝘀𝗳𝗼𝗿𝗺 𝗬𝗼𝘂𝗿 𝗕𝘂𝘀𝗶𝗻𝗲𝘀𝘀 𝘄𝗶𝘁𝗵 𝗢𝘂𝗿 𝗖𝘂𝘁𝘁𝗶𝗻𝗴-𝗘𝗱𝗴𝗲 𝗦𝗼𝗳𝘁𝘄𝗮𝗿𝗲 𝗧𝗲𝗰𝗵𝗻𝗼𝗹𝗼𝗴𝘆</h2>
                        <br />
                        <a href="technology" className="btn btn-primary">Learn More &nbsp; <i className="fa fa-arrow-right"></i></a>
                        <br />
                    </div>
                </div>
                <br/>
                <h4 className="text-center">Core Technologies</h4>
                <br />
                <div className="accordion accordion-flush" id="xcaccordion">
                    {
                        technologiesdata.map((item, index) => {
                            return (
                                <div key={index}>
                                    <div className="accordion-item xc-accordion-item text-center mx-auto p-2 w-75">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button xc-accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={`#${item.tag}`} aria-expanded="true" aria-controls={`${item.tag}`}>
                                                <strong>{item.type}</strong>
                                            </button>
                                        </h2>
                                        <div id={`${item.tag}`} className="accordion-collapse collapse" data-bs-parent="#xcaccordion">
                                            <div className="accordion-body">
                                                <h4><strong>{item.title}</strong></h4>
                                                <small><b>Status : {item.status}</b></small>
                                                <p>{item.description}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <br/>
                                </div>
                            )
                        })
                    }
                </div>
                <div className="text-center xc-bottom-copyright">
                    <div className="container pt-4">
                    <section className="mb-4">
                        <strong>Copyright © XCOLON (OPC) PRIVATE LIMITED. All Rights Reserved.</strong>
                    </section>
                    </div>
                </div>
                <br/>
            </div>
        )
    }
}
export default HomePageComponent