let ContactData = [{
    "type": "Incorporated Company",
    "content": "XCOLON (OPC) PRIVATE LIMITED",
    "submit": "Visit Website",
    "url": "https://www.xcolon.in"
}, {
    "type": "Email Us",
    "content": "xcology.official@gmail.com",
    "submit": "Send Mail",
    "url": "mailto:xcology.official@gmail.com"
}]

export default ContactData