let UpdatesData = [{
    "id": "1001",
    "tag": "update1001",
    "title": "Theos Published",
    "date": "01 July 2024",
    "content": "Introduced our back end technology: Theos"
},{
    "id": "1002",
    "tag": "update1001",
    "title": "Prosopon Published",
    "date": "02 July 2024",
    "content": "Announced our front end technology: Prosopon"
},{
    "id": "1003",
    "tag": "update1001",
    "title": "Company Website Published",
    "date": "03 July 2024",
    "content": "Announced the launch of our company website: www.xcolon.in"
},{
    "id": "1004",
    "tag": "update1001",
    "title": "Our Website Published",
    "date": "04 July 2024",
    "content": "Announced the launch of our website: www.xcology.net"  
}]

export default UpdatesData