import React, { Component } from 'react';
import ContactData from "../../../../Model/Support/Contact"
// import ContactModal from '../../../../Shared/Modals/Contact';

class ContactPageComponent extends Component {
    render() {
        return (
            <div className="text-center mx-auto p-2 w-100">
                <br/><br/>
                {
                    ContactData.map((item, index) => {
                        return (
                            <div key={index}>
                                <br/>
                                <div className="card">
                                    <div className="card-body">
                                        <h5 className="card-title">{item.type}</h5>
                                        <p className="card-text">{item.content}</p>
                                        { item.url !== "" ? <a href={item.url} className="btn btn-primary" target="_blank" rel="noopener noreferrer">{item.submit}</a> : <></>}
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
            // <section className="bg-light py-3 py-md-5">
            //     <div className="container">
            //         <div className="row justify-content-md-center">
            //             <div className="col-12 col-md-10 col-lg-8 col-xl-7 col-xxl-6">
            //                 <h2 className="mb-4 display-5 text-center"><strong>Contact</strong></h2>
            //                 <br/>
            //                 <p className="text-secondary mb-5 text-center">The best way to contact us is to use our contact form below. Please fill out all of the required fields and we will get back to you as soon as possible.</p>
            //             </div>
            //         </div>
            //     </div>
            //     <div className="container">
            //         <div className="row justify-content-lg-center">
            //             <div className="col-12 col-lg-9">
            //                 <div className="bg-white border rounded shadow-sm overflow-hidden">
            //                     <ContactModal />
            //                 </div>
            //             </div>
            //         </div>
            //     </div>
            // </section>
        )
    }
}
export default ContactPageComponent